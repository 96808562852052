import React from "react";
import { motion } from "framer-motion";

const PageFadeAnimation = ({ children }) => {
  return (
    <motion.main
      className="main__container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
    >
      {children}
    </motion.main>
  );
};

export default PageFadeAnimation;
