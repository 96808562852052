import React, { useEffect, useState } from 'react';

import BirdCVCOption from './BirdCVCOption';
import VoteModal from './VoteModal';
import HeaderCVC from './HeaderCVC';

import BirdsData from '../BirdsData.json';

import logo_era from '../../../assets/images/logos/logo_texto_blanco.png'

const BirdsOptions = [
    'bichofue',
    'petirrojo',
    'guacamayacariseca',
    'canario',
    'azulejo',
    // 'tangaramulticolor'
]

const BirdsCVC = () => {

    const [selectedBird, setSelectedBird] = useState(null);
    const [openConfirmVote, setOpenConfirmVote] = useState(false);
    const [votes, setVotes] = useState({});

    const selectBird = (id) => {
        setSelectedBird(id)
        setOpenConfirmVote(true);
    }

    const getVotes = async () => {
        try {
            // http://cvc.reinoalado.com:3200/api/votaciones
            // https://cvc.reinoalado.com/api/votaciones
            // https://backend-votaciones-cvc-nodejs.onrender.com/api/votaciones
            const response = await fetch("https://backend-votaciones-cvc-nodejs.onrender.com/api/votaciones", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json', // Asegúrate de incluir la cabecera Content-Type
                },
            });
            console.log('response', response)

            // Verificar si la respuesta HTTP fue exitosa
            if (!response.ok) {
                throw new Error(`Error HTTP! status: ${response.status}`);
            }

            // Procesar la respuesta si todo fue bien
            const data = await response.json();
            setVotes(data)
            console.log("Votos obtenidos exitosamente:", data);

        } catch (error) {
            // Manejar cualquier error que ocurra
            console.log("Hubo un error al intentar votar:", error.message);
        }
    }

    useEffect(() => {
        getVotes();
    }, [])


    return (
        <div className='w-full h-full md:p-20 md:pb-8 pt-20'>
            <HeaderCVC />
            <section className="text-gray-600 body-font">
                <div className="container flex flex-col px-5 pt-16 justify-center items-center">

                    <div className="w-full md:w-2/3 flex flex-col mb-8 items-center text-center font-bold">
                        <div className='flex flex-row items-center text-4xl'>
                            🦉 <h1
                                className="
                            title-font 
                            sm:text-2xl text-2xl 
                            mb-4 
                            font-bold 
                            text-primary
                            bg-gradient-to-t from-secondary primary/200 to-primary bg-clip-text text-transparent"
                            >
                                ¡Cali necesita tu voz para elegir su ave emblemática!
                            </h1>🐦
                        </div>


                        <h2>
                            Si te apasiona la naturaleza y amas a las aves, esta es tu oportunidad de brillar y de ayudarnos a conservar nuestra biodiversidad. Desde el <b className='font-extrabold'>Consejo Departamental de Protección y Conservación de las Aves</b>, te invitamos a ser parte de una experiencia única: <b className='font-extrabold'>¡Elige el ave que representará nuestra ciudad!</b>🌴✨
                        </h2>
                        <p className="my-4 leading-relaxed">
                            <b className='font-extrabold'>Y hay más...</b> Al votar, podrás tomarte una increíble foto en realidad aumentada con tu ave favorita. 📸💫 *¡Imagina compartir esa selfie épica en tus redes!
                            <br /> 
                            🎯<b className='font-extrabold'>Usa el hashtag #AveEmblemáticaCali</b> y cuéntanos cuál es tu elegida. Queremos ver tus fotos y conocer la historia detrás de tu elección.
                            <br />
                            <br />
                            <br />
                            ¿Qué ave despierta tus mañanas? ¿Cuál te parece que refleja el alma de Cali? ¡Tu voto hará la diferencia! 🦜💚
                            <br />
                            <br />
                            ¡Únete, tu voto cuenta 🕊️
                        </p>
                    </div>
                </div>

                <div className="container flex flex-row flex-wrap justify-center items-start px-5 gap-16">
                    {
                        BirdsOptions.map((option, i) => {

                            const bird_data = BirdsData.aves.find(e => e.id == option)
                            if (!bird_data) return null

                            return (
                                <BirdCVCOption
                                    key={i}
                                    title={bird_data.nombre_comun}
                                    subtitle={bird_data.nombre_cientifico}
                                    image={process.env.PUBLIC_URL + '/images/aves/' + bird_data.foto}
                                    href={bird_data.id}
                                    onClick={() => selectBird(bird_data.id)}
                                    votes={votes[bird_data.id]}
                                />
                            )
                        })
                    }

                </div>

                <VoteModal bird_id={selectedBird} open={openConfirmVote} setOpen={setOpenConfirmVote} getVotes={getVotes} />


                <div className="container flex flex-col items-center mt-20 p-4 bg-primary">
                    <p className="mb-8 font-bold text-center leading-relaxed text-md text-white">
                        ¡Descarga el app y viaja por El Reino Alado!
                    </p>

                    <div className="container flex flex-row flex-wrap justify-center items-center px-5 gap-4">
                        <button className="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none"
                            onClick={() => { window.open('https://play.google.com/store/apps/details?id=com.KaleidoLab.ElReinoAlado', '_blank') }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 h-5" viewBox="0 0 512 512">
                                <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
                            </svg>
                            <span className="ml-4 flex items-start flex-col leading-none">
                                <span className="text-xs text-gray-600">Android</span>
                                <span className="title-font font-medium text-xs">Google Play</span>
                            </span>
                        </button>

                        <button className="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none"
                            onClick={() => { window.open('https://apps.apple.com/us/app/el-reino-alado/id6502377698', '_blank') }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 h-5" viewBox="0 0 305 305">
                                <path d="M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z"></path>
                                <path d="M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z"></path>
                            </svg>
                            <span className="ml-4 flex items-start flex-col leading-none">
                                <span className="text-xs text-gray-600 ">iOS</span>
                                <span className="title-font font-medium text-xs">App Store</span>
                            </span>
                        </button>
                    </div>

                    <img src={logo_era} className='h-32 object-contain mt-4' />

                    <nav>
                        <div className="grid grid-flow-col gap-4 mt-4 text-white">
                            <a href='https://www.instagram.com/elreinoalado' target="_blank">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    className="fill-current">
                                    <path
                                        fillRule="evenodd" d="M12 2c-2.716 0-3.056.012-4.123.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.772 1.153A4.9 4.9 0 0 0 2.525 5.45c-.247.636-.416 1.363-.465 2.427C2.011 8.944 2 9.284 2 12s.011 3.056.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.153 1.772a4.9 4.9 0 0 0 1.772 1.153c.636.247 1.363.416 2.427.465c1.067.048 1.407.06 4.123.06s3.056-.012 4.123-.06c1.064-.049 1.791-.218 2.427-.465a4.9 4.9 0 0 0 1.772-1.153a4.9 4.9 0 0 0 1.153-1.772c.247-.636.416-1.363.465-2.427c.048-1.067.06-1.407.06-4.123s-.012-3.056-.06-4.123c-.049-1.064-.218-1.791-.465-2.427a4.9 4.9 0 0 0-1.153-1.772a4.9 4.9 0 0 0-1.772-1.153c-.636-.247-1.363-.416-2.427-.465C15.056 2.012 14.716 2 12 2m0 1.802c2.67 0 2.986.01 4.04.058c.976.045 1.505.207 1.858.344c.466.182.8.399 1.15.748c.35.35.566.684.748 1.15c.136.353.3.882.344 1.857c.048 1.055.058 1.37.058 4.041c0 2.67-.01 2.986-.058 4.04c-.045.976-.208 1.505-.344 1.858a3.1 3.1 0 0 1-.748 1.15c-.35.35-.684.566-1.15.748c-.353.136-.882.3-1.857.344c-1.054.048-1.37.058-4.041.058c-2.67 0-2.987-.01-4.04-.058c-.976-.045-1.505-.208-1.858-.344a3.1 3.1 0 0 1-1.15-.748a3.1 3.1 0 0 1-.748-1.15c-.137-.353-.3-.882-.344-1.857c-.048-1.055-.058-1.37-.058-4.041c0-2.67.01-2.986.058-4.04c.045-.976.207-1.505.344-1.858c.182-.466.399-.8.748-1.15c.35-.35.684-.566 1.15-.748c.353-.137.882-.3 1.857-.344c1.055-.048 1.37-.058 4.041-.058m0 11.531a3.333 3.333 0 1 1 0-6.666a3.333 3.333 0 0 1 0 6.666m0-8.468a5.135 5.135 0 1 0 0 10.27a5.135 5.135 0 0 0 0-10.27m6.538-.203a1.2 1.2 0 1 1-2.4 0a1.2 1.2 0 0 1 2.4 0" /></svg>
                            </a>

                            {/* <a>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    viewBox="0 0 24 24"
                                    className="fill-current">
                                    <path
                                        d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
                                </svg>
                            </a> */}

                            <a href='https://www.youtube.com/@kaleidolabVR-AR' target='_blank'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    className="fill-current">
                                    <path
                                        d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
                                </svg>
                            </a>

                        </div>
                    </nav>

                </div>
            </section>
        </div>
    )
}

export default BirdsCVC;